import React from "react"
import Jumbotron from "src/components/jumbotron"
import Layout from "src/components/layout"
import { Grid } from "@material-ui/core"
import MindanceAppSection from "src/components/marketing/mindance-app-section"
import GetStartedSection from "src/components/marketing/get-started-section"
import SignatureSection from "src/components/marketing/signature-section"
import EmailInput from "src/components/marketing/email-input"
import "src/styles/pages/marketing-page.scss"
import TitleStrip from "src/components/marketing/title-strip"
import SEO from "src/components/seo"

const REGISTRATION_CODE = "w4XCu8OYwoLDmsODw4_CoQ="

export default function SteelcaseLandingPage() {
  return (
    <Layout className="marketing-page-root">
      <TitleStrip
        logos={[
          "/assets/img/logos/Steelcase.png",
          "https://mindance-forestry.s3-eu-west-1.amazonaws.com/upload/logo@2x.png",
        ]}
      />
      <Jumbotron
        className="marketing-jumbotron"
        section={{
          image:
            "https://mindance-forestry.s3-eu-west-1.amazonaws.com/upload/200306_Mindance_Illus_06@2x.png",
          title: "Finally: less stress, better sleep and improved wellbeing!",
          description:
            "Employees at Steelcase AG can now access the Mindance offer free of charge. Therefore, request your activation code for the Mindance app, and benefit from all its advantages.",
        }}
      >
        <EmailInput
          placeholder="E-Mail address"
          registrationCode={REGISTRATION_CODE}
          buttonText="Request activation code"
          successText="Success"
        />
      </Jumbotron>
      <SEO
        image="https://mindance-forestry.s3-eu-west-1.amazonaws.com/upload/200306_Mindance_Illus_06@2x.png"
        title="Steelcase"
        description="Mitarbeitende der Steelcase AG und Tochtergesellschaften erhalten jetzt kostenlos Zugriff auf das Angebot von Mindance. Fordern Sie dazu Ihren Aktivierungscode für die Mindance-App an, um alle Vorteile zu nutzen."
      />
      <Grid container component="section" className="bounded-w">
        <Grid item xs={12} md={6}>
          <h1>Reduce stress and increase wellbeing</h1>
          <p>
            Whether it is time-related pressure, emotionally-taxing work or
            shift patterns – along with all the enjoyable moments, working at
            Steelcase AG can also lead to exhaustion, frustration and stress.
            And then there are your personal challenges during your leisure
            hours. In the long term, sources of stress of this kind take a toll
            on an individual’s physical and mental health – burnout, trouble
            sleeping, back pain, cardiovascular problems and depression are just
            a few examples of the negative consequences of constant stress.
          </p>
          <br />
          <p>
            <b>
              The good news: With Mindance, you can reduce your stress and
              increase your well-being on a sustainable basis!
            </b>
          </p>
          <br />
          <h2 className="green-text">
            Available free of charge for all employees of Steelcase AG
          </h2>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className="screenshot-container cover-section-edge"
        >
          <img
            className="screenshot"
            alt="feed"
            src="/assets/img/marketing/mindfrinds_achtsamkeit_reflection.jpg"
          />
        </Grid>
      </Grid>
      <MindanceAppSection
        header="The Mindance App"
        titles={[
          "Check your mental health and get your personal exercise plan",
          "Large choice of exercises and courses",
          "All your progress at a glance",
        ]}
        descriptions={[
          "Get started with a questionnaire that will show you the areas in which you can improve your mental health. On the basis of your personal result, you then get a plan that contains various exercises. Finally, you decide how much time you want to invest per week. From five minutes once a week, to 20 minutes each morning and evening every day – the choice is yours.",
          "In the app you will find an 8-week basic training program that can help you to make better sense of your own thoughts and feelings. You can also choose between exercises that cover a variety of topics, such as reducing stress, strengthening relationships, sleeping better or cultivating mindfulness, and determine the length of the exercise – from 5 to 15 minutes – which suits you at that moment in time.",
          "In your profile, you will find the timeline of the exercises you have completed, statistics about your training with Mindance and the results of the questionnaire. Our tip: Regular practice makes perfect, so participating on a regular basis is more important than occasionally and for a long time.",
        ]}
      />
      <GetStartedSection
        title="Get started straight away"
        subtitle="To install the app, just take these three steps:"
        steps={[
          "Download the Mindance app in the App Store / Play Store, you will find it using the keyword “Mindance”.",
          "Fill the registration form out to receive an activation code at your email address.",
          "Create your personal, anonymous profile using the activation code.",
        ]}
        emailComponent={
          <EmailInput
            placeholder="E-Mail address"
            registrationCode={REGISTRATION_CODE}
            buttonText="Request activation code"
            successText="Success"
          />
        }
      />
      <SignatureSection
        text="Have fun and best regards"
        signature="The Mindance team"
      />
    </Layout>
  )
}
